import { useState, useContext } from "react";
import PropTypes from "prop-types";
import UserContext from "../../../../context/UserContext";
import Error from "../../../toast/erros";

function AllPlayers() {
  const { gameEvent } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <div className="invite-wrapper waiting-list-wrapper">
      <div className="invite-user waiting-user">
        {gameEvent?.games?.map((gameData) => (
          <div className="table-line-dealer" key={gameData?.id}>
            <span>Table</span>
            <span>
              {gameData?.tableNumber ? ` #${gameData?.tableNumber}` : ""}
              {" "}
            </span>
            <div>
              {Array.isArray(gameData?.waitingPlayers)
                && gameData?.waitingPlayers?.map((player) => (
                  <UserList key={player?.id} player={player} />
                ))}
            </div>
          </div>
        ))}
      </div>
      {errorMessage ? (
        <Error message={errorMessage} closeAction={() => setErrorMessage("")} />
      ) : (
        ""
      )}
    </div>
  );
}

export default AllPlayers;

function UserList({ player }) {
  return (
    <div className="invite-user-list">
      <div className="invite-user-info">
        <div className="invite-user-cover">
          <span className="online-user" />
          <img
            src={player?.avatar}
            onError={({ currentTarget }) => {
              // eslint-disable-next-line no-param-reassign
              currentTarget.onerror = null; // prevents looping
              // eslint-disable-next-line no-param-reassign
              currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
            }}
            alt=""
          />
        </div>
        <p title={player?.name}>{player?.name}</p>
      </div>
    </div>
  );
}

UserList.propTypes = {
  player: PropTypes.object.isRequired,
};
